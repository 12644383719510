import loadable from '@loadable/component';
import React from 'react';

const ConversionPanelStandard = loadable(() => import("./ConversionPanelStandard"))
const AnnouncementBanner = loadable(() => import("./ConversionPanelAnnounceBanner"))

const ConversionPanel = ({ component }) => {
  const {
    type
  } = component
  switch (type) {
    case "standard":
      return <ConversionPanelStandard component={component} />;
    case "announcement-banner":
      return <AnnouncementBanner component={component} />;
    default: 
      return <ConversionPanelStandard component={component} />;
  }
}

export default ConversionPanel
import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { IconBlock } from "../components/IconBlock"
import ConversionPanel from "../components/ConversionPanel/ConversionPanel"

const NotFoundPage = ({ data }) => {
  const { contentfulComponentConversionPanel, contentfulComponentIconBlock } = data
  
  return (
    <Layout headerTheme={{ theme: "dark", background: "#090069" }}>
      <Seo 
        title="404: Not found"
        description="404: Not found"
        keywords="Workgrid, Marketing, 404"
      />
      <div style={{marginTop: "100px"}} />
      <ConversionPanel component={contentfulComponentConversionPanel} />
      <IconBlock component={contentfulComponentIconBlock} />
    </Layout>
  )
} 

export default NotFoundPage

export const pageNotFoundQuery = graphql`
  query pageNotFoundQuery {
    contentfulComponentConversionPanel(id: {eq: "f7d2eecd-5c89-598c-9104-f6367a2b324b"}) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
      noTopPadding
      noBottomPadding
      reference {
        ... on ContentfulComponentTrustbar {
          __typename
          id
          heading
          companies {
            id
            name
            logo {
              gatsbyImageData
              file {
                url
              }
            }
          }
        }
        ... on ContentfulComponentTestimonial {
          __typename
          id
          author {
            id
            fullName
            role
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
            company {
              id
              name
            }
          }
          quote {
            quote
          }
        }
        ... on ContentfulComponentImage {
          __typename
          id
          primaryImage {
            gatsbyImageData
            file {
              url
            }
            description
          }
        }
      }
    }
    contentfulComponentIconBlock(id: {eq: "3641d676-0bb1-50cf-a014-0618c3fde99e"}) {
      __typename
      contentful_id
      internalName
      heading
      kicker
      type
      background
      noTopPadding
      noBottomPadding
      backgroundImage {
        id
        primaryImage {
          gatsbyImageData
          file {
            url
          }
        }
      }
      subheading {
        subheading
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      icons {
        id
        alignCaption
        caption {
          raw
        }
        description
        internalLink
        internalName
        primaryImage {
          gatsbyImageData
          file {
            url
          }
          description
        }
        title
        url
        featureGridTitle
        featureGridDescription
      }
    }
  }
`

import loadable from '@loadable/component';
import React from 'react';

const IconBlockStandard = loadable(() => import("./IconBlockStandard"))
const IconBlockOffsetGrid = loadable(() => import("./IconBlockOffsetGrid"))
const IconBlockFeatureSection = loadable(() => import("./IconBlockFeatureSection"))
const IconBlockFeaturedPersona = loadable(() => import("./IcionBlockFeaturedPersona/FeaturedPersona"))

const IconBlock = ({ component }) => {
  const {
    type
  } = component
  switch (type) {
    case "standard":
      return <IconBlockStandard component={component} />;
    case "offset-grid":
      return <IconBlockOffsetGrid component={component} />;
    case "feature-section":
      return <IconBlockFeatureSection component={component} />;
    case "featured-persona":
      return <IconBlockFeaturedPersona component={component} />
    default: 
      return <IconBlockStandard component={component} />;
  }
}

export default IconBlock
